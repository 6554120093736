import { HTMLAttributes } from "react";

export default function InputError({
    message,
    className = "",
    ...props
}: HTMLAttributes<HTMLParagraphElement> & { message?: string | string[] }) {
    return message ? (
        <p
            {...props}
            className={"text-sm text-red-600 dark:text-red-400 " + className}
        >
            {Array.isArray(message) ? (
                <ul>
                    {message.map((m, key) => (
                        <li key={key}>{m}</li>
                    ))}
                </ul>
            ) : (
                message
            )}
        </p>
    ) : null;
}
